@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  overflow-x: hidden;
  border-top: #0057b8 solid 5px;
}

.profile-img {
  filter: grayscale(100%);
}

.swiper-button-prev,
.swiper-button-next {
  color: #d3d3d3 !important;
}

.swiper-pagination-bullet {
  background-color: #d3d3d3 !important;
}

.badge-sm {
  font-size: 0.5rem !important;
}

.code-block {
  font-family: "Courier New", Courier, monospace;
  background-color: #d3d3d3;
  padding: 1px 2px;
  border-radius: 2px;
  font-weight: 600;
}

/* Badge Color */
.SASS {
  border: 1px solid #c46192;
  color: #c46192;
}

.REACT {
  border: 1px solid #0077b6;
  color: #0077b6;
}

.CYPRESS {
  border: 1px solid #6c757d;
  color: #6c757d;
}

.REDUX {
  border: 1px solid #560bad;
  color: #560bad;
}

.FIREBASE {
  border: 1px solid #e04031;
  color: #e04031;
}

.API {
  border: 1px solid #704b32;
  color: #704b32;
}

.NEXTJS {
  border: 1px solid #757bc8;
  color: #757bc8;
}

.CSS {
  border: 1px solid #023e8a;
  color: #023e8a;
}

.TAILWIND {
  border: 1px solid #5bc0be;
  color: #5bc0be;
}

.HTML {
  border: 1px solid #e87d27;
  color: #e87d27;
}

.CSS {
  border: 1px solid #006d77;
  color: #006d77;
}

.MONGODB {
  border: 1px solid #4ca33e;
  color: #4ca33e;
}

.EXPRESS {
  border: 1px solid #000;
  color: #000;
}

.JAVASCRIPT {
  border: 1px solid #c9a227;
  color: #c9a227;
}/*# sourceMappingURL=index.css.map */