@tailwind base;
@tailwind components;
@tailwind utilities;

$primary-color: #0057b8;
$secondary-color: #d3d3d3;

@mixin badgeColor($color) {
  border: 1px solid $color;
  color: $color;
}

body {
  overflow-x: hidden;
  border-top: $primary-color solid 5px;
}

.profile-img {
  filter: grayscale(100%);
}

.swiper-button-prev,
.swiper-button-next {
  color: #d3d3d3 !important;
}

.swiper-pagination-bullet {
  background-color: #d3d3d3 !important;
}

.badge-sm {
  font-size: 0.5rem !important;
}

.code-block {
  font-family: 'Courier New', Courier, monospace;
  background-color: $secondary-color;
  padding: 1px 2px;
  border-radius: 2px;
  font-weight: 600;
}

/* Badge Color */
.SASS {
  @include badgeColor(#c46192);
}
.REACT {
  @include badgeColor(#0077b6);
}

.CYPRESS {
  @include badgeColor(#6c757d);
}
.REDUX {
  @include badgeColor(#560bad);
}
.FIREBASE {
  @include badgeColor(#e04031);
}
.API {
  @include badgeColor(#704b32);
}
.NEXTJS {
  @include badgeColor((#757bc8));
}
.CSS {
  @include badgeColor(#023e8a);
}

.TAILWIND {
  @include badgeColor(#5bc0be);
}
.HTML {
  @include badgeColor((#e87d27));
}
.CSS {
  @include badgeColor(#006d77);
}
.MONGODB {
  @include badgeColor(#4ca33e);
}
.EXPRESS {
  @include badgeColor(#000);
}
.JAVASCRIPT {
  @include badgeColor(#c9a227);
}
